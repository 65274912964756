import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Card, Typography, Space, Alert } from "antd";
import { GoogleOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { useWelcome } from "../components/WelcomeContext"; // Adjust the import path

const { Title } = Typography;

const Signup = () => {
  const [error, setError] = useState("");
  const { setWelcomeMessage } = useWelcome();

  const navigate = useNavigate(); // Initialize the navigate function
  const provider = new GoogleAuthProvider();

  const handleSignup = async (values) => {
    const { email, password } = values;
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setWelcomeMessage(`Welcome, ${email}! Your account has been created.`);

      // Redirect or perform other actions after successful signup
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setWelcomeMessage(
        `Welcome, ${result.user.email}! Your account has been created.`
      );
      //   await signInWithPopup(auth, provider);
      // Redirect to the homepage upon successful Google sign-in
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card style={{ width: 400 }}>
        <Title level={2} style={{ textAlign: "center" }}>
          Signup
        </Title>
        <Form onFinish={handleSignup}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Signup
            </Button>
          </Form.Item>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </Form>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button
            type="default"
            icon={<GoogleOutlined />}
            onClick={handleGoogleSignIn}
            style={{ width: "100%", marginBottom: 10 }}
          >
            Sign up with Google
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default Signup;

import React, { useMemo, useEffect, useState } from "react";
import {
  Layout,
  Card,
  Divider,
  List,
  Skeleton,
  Button,
  message,
  Steps,
} from "antd";
import {
  ChartsAndTables,
  CompanyCharts,
  CompanyChartsMultiple,
  DCFFCompany,
} from "./ChartsTables";
import "./App.css";
import {
  ArrowUpOutlined,
  DownCircleTwoTone,
  RightOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
  SyncOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  RedoOutlined,
  CopyOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  fetchAgentNameUpdatedAdy,
  StreamComponent,
  fetchSymbolsTable,
} from "./UserDataDynamoDB";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { IncomeStatementMetricsChart } from "./ChartsTables";

const { Content } = Layout;
const { Step } = Steps;

const MemoizedChartsAndTables = ({
  tableData,

  question,
}) => {
  // console.log(tableData);
  // console.log(RouterData);

  return useMemo(() => {
    if (tableData?.router === "CompanyAnalysis") {
      return (
        <CompanyCharts
          question={question}
          symbols={tableData?.entity}
          subrouter={tableData?.subrouter}
        />
      );
    }
    if (["OutOfScope", "LLM", "RAG"].includes(tableData?.router)) {
      return null;
    }
    if (["SQL"].includes(tableData?.router)) {
      return (
        <ChartsAndTables
          input_symbols={tableData?.table_data.replace(/,\s+/g, ",")}
          sql_columns={tableData?.extracted_columns?.split("@@@")[1]}
        />
      );
    } else {
      return null;
    }
  }, []);
};

const icons = [
  <CheckCircleOutlined />,
  <InfoCircleOutlined />,
  <WarningOutlined />,
  <SyncOutlined spin />,
  <SmileOutlined />,
];

const getRandomIcon = () => {
  const randomIndex = Math.floor(Math.random() * icons.length);
  return icons[randomIndex];
};

const SmallCard = ({ title, isActive }) => (
  <Card
    style={{
      borderRadius: "12px",
      backgroundColor: isActive ? "#d4edda" : "#f0f2f5", // Light green for active cards
      height: "160px",
      textAlign: "center",
      padding: "10px",
      margin: "3px 0",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    }}
  >
    <h4>{title}</h4>
    <div style={{ marginTop: "10px" }}>{getRandomIcon()}</div>
  </Card>
);

const MainChat = ({
  activeThread,
  skeletonLoading,
  skeletonLLMLoading,
  newMessage,
  setNewMessage,
  messagesEndRef,
  isMobile,
  streamActive,
  setStreamActive,
  user_value_cookie,
  InterruptWrite,
  setInterruptWrite,
  regenerate,
  setRegenerate,
  currentStep,
  setCurrentStep,
}) => {
  const [AgentNameData, setAgentNameData] = useState("");
  const [RouterData, setRouterData] = useState("");
  const [SymbolsNameData, setSymbolsNameData] = useState([]);
  const [llmResponseData, setLlmResponseData] = useState("test");
  const [RelatedQuestionShow, setRelatedQuestionShow] = useState(true);

  // console.log('activeThread:',activeThread)

  let symbolsData = "";

  useEffect(() => {
    const fetchAgentData = async () => {
      if (streamActive && newMessage?.length > 0) {
        try {
          const agentData = await fetchAgentNameUpdatedAdy(newMessage);
          console.log(agentData);

          setAgentNameData(agentData?.agent_name?.Router);
          setRouterData(agentData?.agent_name);

          // console.log(name);
        } catch (error) {
          console.error("Error fetching agent name:", error);
          setAgentNameData(null); // Update state to handle the error
        }
      }
    };

    fetchAgentData(); // Call the async function to perform the fetch operation
    // setRelatedQuestionShow(false);
  }, [streamActive, newMessage]); // Dependencies array to control the effect execution

  // console.log("Expert Agent Name:", AgentNameData);
  const handleRewrite = (msg) => {
    // Set streamActive to false initially
    setStreamActive(false);
    setRegenerate(!regenerate);
  };

  const handleShare = () => {
    message.info("Share Chat Feature coming soon");
  };

  // console.log('RelatedQuestionShow:',RelatedQuestionShow)
  const htmlToPlainText = (html) => {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    let text = tempDivElement.textContent || tempDivElement.innerText || "";
    text = text.replace(/\s+/g, " ").trim();
    text = text.replace(/\n/g, " ").replace(/\s{2,}/g, " ");
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
  };

  const handleCopy = (msg) => {
    const plainText = htmlToPlainText(llmResponseData); // Convert HTML to plain text
    const textToCopy = `Question: ${msg.message}\n\nStockbuzz.ai Answer:\n${plainText}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Message copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy: " + err.message);
      });
  };

  // console.log(currentStep);

  useEffect(() => {
    if (!streamActive) {
      setCurrentStep(-1);
    } else if (streamActive && currentStep === -1) {
      const interval = setInterval(() => {
        setCurrentStep((prevStep) => {
          if (prevStep > 3) {
            clearInterval(interval);
            return prevStep;
          }
          return prevStep + 1;
        });
      }, 800);
      return () => clearInterval(interval);
    }
  }, [streamActive]);

  return (
    <Content
      style={{
        padding: "5%",
        minHeight: 280,
        overflow: "auto",
        maxWidth: "90%",
        marginLeft: "1%",
        maxWidth: isMobile ? "100%" : "90%",
        marginLeft: isMobile ? "0" : "10%",
        marginRight: isMobile ? "0" : "10%",
        marginBottom: "10%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          marginTop: isMobile ? "20%" : "5%",
        }}
      >
        {activeThread &&
          activeThread.messages?.map((msg, index) => (
            <div key={index}>
              <Card
                style={{
                  borderRadius: "8px",
                  backgroundColor:
                    msg.type === "question" ? "	#e9e5e5" : "#e6f7ff",
                  height: "auto",
                  margin: isMobile ? "0 5%" : "0",
                  width: isMobile ? "90%" : "auto",
                  marginBottom: "1%",
                }}
              >
                <h3
                  style={{
                    margin: "0",
                    fontSize: "medium",
                    color: msg.type === "question" ? "#000" : "#1890ff",
                  }}
                >
                  {msg.message}
                </h3>
              </Card>

              {skeletonLoading && index === activeThread.messages.length - 1 ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      margin: "2 auto",
                    }}
                  >
                    {/* {currentStep >= 0 && (
                      <div style={{ width: "25%", textAlign: "left" }}>
                        <SmallCard
                          title="Routing to Agent"
                          isActive={currentStep > 0}
                        />
                        <CheckCircleOutlined />
                      </div>
                    )}
                    {currentStep >= 1 && (
                      <div style={{ width: "25%", textAlign: "center" }}>
                        <SmallCard
                          title="Nvidia-Metrics"
                          isActive={currentStep > 1}
                        />
                        <CheckCircleOutlined />
                      </div>
                    )}
                    {currentStep >= 2 && (
                      <div style={{ width: "25%", textAlign: "right" }}>
                        <SmallCard
                          title="Aggregating output"
                          isActive={currentStep > 2}
                        />
                        <CheckCircleOutlined />
                      </div>
                    )}

                    {currentStep >= 3 && (
                      <div style={{ width: "25%", textAlign: "right" }}>
                        <SmallCard
                          title="Insights"
                          isActive={currentStep > 3}
                        />
                        <CheckCircleOutlined />
                      </div>
                    )} */}
                  </div>

                  <Skeleton
                    active
                    paragraph={{ rows: 4 }}
                    style={{
                      maxWidth: "100%",
                      alignSelf: "flex-start",
                      padding: "10px",
                      borderRadius: "8px",
                      marginBottom: "10px",
                    }}
                  />
                  <Skeleton
                    active
                    paragraph={{ rows: 2 }}
                    style={{
                      maxWidth: "100%",
                      alignSelf: "flex-start",
                      padding: "10px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      marginBottom: "10px",
                    }}
                  />
                </div>
              ) : (
                <>
                  {
                    <div>
                      <Card>
                        <div className="markdown-preview">
                          <MarkdownPreview
                            source={msg.response?.llm_response?.replace(
                              /<a[^>]+href="[^"]*"[^>]*>(.*?)<\/a>/g,
                              "$1"
                            )}
                          />
                        </div>
                        <div className="stream-markdown-preview">
                          <StreamComponent
                            question={newMessage}
                            streamActive={streamActive}
                            setStreamActive={setStreamActive}
                            activeThread={activeThread}
                            user_value_cookie={user_value_cookie}
                            AgentNameData={AgentNameData}
                            symbols_sql={msg?.response}
                            symbols_table={SymbolsNameData}
                            setLlmResponseData={setLlmResponseData}
                            setRelatedQuestionShow={setRelatedQuestionShow}
                            InterruptWrite={InterruptWrite}
                            setInterruptWrite={setInterruptWrite}
                            RouterData={RouterData}
                          />
                        </div>
                      </Card>
                    </div>
                  }
                  <div style={{ marginTop: "1%" }}>
                    <MemoizedChartsAndTables
                      tableData={msg.response || ""}
                      question={newMessage}
                    />
                  </div>

                  {index === activeThread.messages.length - 1 &&
                    !streamActive && (
                      <div style={{ marginTop: "10px", textAlign: "left" }}>
                        <Button
                          style={{
                            marginRight: "10px",
                            border: "none", // Removes the border
                            fontSize: "12px", // Smaller text
                          }}
                          icon={<RedoOutlined style={{ fontSize: "16px" }} />} // Smaller icon
                          onClick={() => handleRewrite(msg)}
                        >
                          Regenerate
                        </Button>
                        <Button
                          style={{
                            marginRight: "10px",
                            border: "none", // Removes the border
                            fontSize: "12px", // Smaller text
                          }}
                          icon={<CopyOutlined style={{ fontSize: "16px" }} />} // Smaller icon
                          onClick={() => handleCopy(msg)}
                        >
                          Copy
                        </Button>
                        <Button
                          style={{
                            border: "none", // Removes the border
                            fontSize: "12px", // Smaller text
                            color: "grey", // Grey out the button
                            cursor: "not-allowed", // Show not-allowed cursor
                          }}
                          icon={
                            <ShareAltOutlined
                              style={{ fontSize: "16px", color: "grey" }}
                            />
                          } // Smaller icon
                          onClick={handleShare}
                        >
                          Share
                        </Button>
                      </div>
                    )}

                  {index === activeThread.messages.length - 1 &&
                    llmResponseData?.length == 0 && (
                      <div>
                        <p>
                          Sorry, I can't find the requested answer. Please try
                          some other query like-
                        </p>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                            gap: "10px",
                            textAlign: "center",
                            maxWidth: isMobile ? "100%" : "100%",
                            margin: "0 auto",
                          }}
                        >
                          <Button
                            className="custom-button"
                            onClick={() =>
                              setNewMessage(
                                "Tell me about OpenAI and its latest deals with FAANG companies"
                              )
                            }
                          >
                            Tell me about OpenAI and its latest deals with FAANG
                            companies
                            <ArrowUpOutlined className="hover-icon" />
                          </Button>
                          <Button
                            onClick={() =>
                              setNewMessage(
                                "top 10 companies in e-commerce with highest revenues"
                              )
                            }
                            className="custom-button"
                          >
                            Top 10 companies in e-commerce with highest revenues
                            <ArrowUpOutlined className="hover-icon" />
                          </Button>
                          {!isMobile && (
                            <>
                              <Button
                                className="custom-button"
                                onClick={() =>
                                  setNewMessage(
                                    "Tell me about OpenAI and its latest deals with FAANG companies"
                                  )
                                }
                              >
                                Tell me about OpenAI and its latest deals with
                                FAANG companies
                                <ArrowUpOutlined className="hover-icon" />
                              </Button>
                              <Button
                                className="custom-button"
                                onClick={() =>
                                  setNewMessage(
                                    "Analyse latest earning call of Meta"
                                  )
                                }
                              >
                                Analyse latest earning call of Meta
                                <ArrowUpOutlined className="hover-icon" />
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                </>
              )}

              {msg.response?.relevant_questions?.length > 0 &&
                (index < activeThread.messages.length - 1 ||
                  RelatedQuestionShow) && (
                  <Card style={{ marginTop: "1%" }}>
                    <div
                      style={{
                        marginBottom: "1%",
                        fontWeight: "bold",
                        fontSize: "large",
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Related Questions:
                    </div>
                    <List
                      dataSource={msg.response?.relevant_questions
                        ?.split("\n")
                        ?.filter((item) => item.trim() !== "")}
                      renderItem={(item) => (
                        <List.Item
                          style={{
                            borderBottom: "none",
                            marginBottom:
                              index ===
                              msg?.response?.relevant_questions
                                ?.split("\n")
                                .filter((item) => item.trim() !== "").length -
                                1
                                ? "0px"
                                : "-10px",
                          }}
                        >
                          <div
                            onClick={() =>
                              setNewMessage(item.replace(/^\d+\.\s*/, ""))
                            }
                            style={{
                              cursor: "pointer",
                              fontFamily: "Arial",
                              fontSize: "15px",
                              fontWeight: "normal",
                            }}
                          >
                            {item}
                          </div>
                        </List.Item>
                      )}
                    />
                  </Card>
                )}

              <Divider />
            </div>
          ))}
        <div ref={messagesEndRef} />
      </div>
    </Content>
  );
};

export default MainChat;

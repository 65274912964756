import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";
import {
  Form,
  Input,
  Button,
  Card,
  Typography,
  Space,
  Alert,
  Checkbox,
} from "antd";
import { GoogleOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { useWelcome } from "../components/WelcomeContext"; // Adjust the import path

const { Title } = Typography;

const Login = () => {
  const [error, setError] = useState("");
  const { setWelcomeMessage } = useWelcome();
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate(); // Initialize the navigate function
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";

    if (savedRememberMe) {
      form.setFieldsValue({
        email: savedEmail,
        password: savedPassword,
      });
      setRememberMe(savedRememberMe);
    }
  }, []);

  const [form] = Form.useForm();

  const handleLogin = async (values) => {
    const { email, password } = values;
    console.log('email:',email)
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setWelcomeMessage(`Welcome back, ${email}!`);

      if (rememberMe) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        localStorage.setItem("rememberMe", rememberMe);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
        localStorage.removeItem("rememberMe");
      }

      navigate("/");
    } catch (error) {
      // Set a user-friendly error message
      switch (error.code) {
        case "auth/wrong-password":
          setError("Incorrect password. Please try again.");
          break;
        case "auth/user-not-found":
          setError("No user found with this email.");
          break;
        case "auth/invalid-email":
          setError("Invalid email format. Please enter a valid email.");
          break;
        case "auth/invalid-credential":
          setError("Invalid credentials. Please try again.");
          break;
        case "auth/too-many-requests":
          setError(
            "Access to this account has been temporarily disabled due to too many failed login attempts. You can immediately restore it by resetting your password or try again later."
          );
          break;
        default:
          setError(error.message);
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setWelcomeMessage(`Welcome back, ${result.user.email}!`);
      //   await signInWithPopup(auth, provider);
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card style={{ width: 400 }}>
        <Title level={2} style={{ textAlign: "center" }}>
          Login
        </Title>
        <Form
          form={form}
          name="login"
          initialValues={{ remember: true }}
          onFinish={handleLogin}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            >
              Remember Me
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Login
            </Button>
          </Form.Item>
          {error && (
            <Alert
              message="Error"
              description={error}
              type="error"
              showIcon
              closable
              onClose={() => setError("")}
            />
          )}
        </Form>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button
            type="default"
            icon={<GoogleOutlined />}
            onClick={handleGoogleSignIn}
            style={{ width: "100%", marginTop: "2%" }}
          >
            Sign in with Google
          </Button>
          <Link to="/reset-password" style={{ float: "right" }}>
            Forgot Password?
          </Link>
        </Space>
      </Card>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { List, Space, Avatar } from "antd";
import Cookies from "js-cookie";
import {  generateRandomId } from "./SamplePrompts";

import { StockOutlined, FieldTimeOutlined } from "@ant-design/icons";
import "./App.css";

const predefinedQuestions = [
  {
    id: 1,
    name: "Which companies have shown price drop more than 8% in last month despite a positive result i.e >10% quarterly revenue change. Sort it by Market Cap.",
    message:
      "Agents would not only find the companies but also mention Root cause and challenges based on company announcements e.g UBER facing challenges due to Regulatory scrutiny",
  },
  {
    id: 2,
    name: "Top 5 companies with market cap less than 100Bn and involved in Genetic research",
    message:
      "SQL agents would identify companies based on market cap filter with companies in relevant sectors on 'gene editing', 'genome editing', 'genetic testing', 'genetic medicines', 'gene therapy' ",
  },
  {
    id: 3,
    name: "What is Cigar-Butt investing?",
    message:
      "LLM Agent would look for 100+ Financial textbooks uploaded in database",
  },
  {
    id: 4,
    name: "What should I buy based on latest results. I am looking for companies whose results were announced in last month and saw annual revenue increase more than 20%?",
    message:
      "SQL can efficiently query databases for companies announcing results betweein TODAY and TODAY-30 days with additional filters",
  },
  {
    id: 5,
    name: "I am a value investor and I specifically track companies based on my name 'Aditi', This ratio sorts companies based on PriceChange1Month divided by ROE with market cap more than 100Bn. Provide Top 5 companies.",
    message:
      "Stockbuzz.ai would write a specific SQL tailored to your use case and showcase top companies",
  },
  {
    id: 6,
    name: "What is AWS Bedrock?",
    message:
      "RAG agents would look out for relevant mention of this service in 200k+ SEC filings including Earning Transcripts, 10K, 10Q, 8K etc",
  },

  {
    id:7,
    name:"Compare companies in GPU Manufacturing sector with focus on their profits",
    message:"SQL agent would read reports on relevant companies who have mentioned their businesses related to GPU manufacturing in SEC filings"

  },
  {
    id:7,
    name:"Tell me about OpenAI and its latest deals with FAANG companies",
    message:"Provide details about Microsoft-OpenAI collaboration"
    
  },
  {
    id:9,
    name:"What are challenges faced by Starbucks based on their latest earning report?",
    message:"RAG agent will read 200k+ reports in less than 2 secs and provide a concise summary on supply-chain challenges, China issues and Price Competition."
    
  },
  {
    id: 10,
    name: "List companies with highest P/E ratio in the military and defence sector",
    message: "SQL agents will identify and sort companies in the MILITARY sector by their P/E ratio.",
  },
 

  {
    id: 11,
    name: "Identify top performing stocks in the S&P 500 over the past year",
    message: "SQL agents will query the database to find top performing stocks in the S&P 500 based on annual performance.",
  },
  {
    id: 12,
    name: "Which companies have the best dividend yield in the utilities sector?",
    message: "SQL agents will rank companies in the utilities sector by their dividend yield.",
  },

  {
    id:13,
  name:"How is the 6nm chip development going for Intel?",
message:"Read through latest Intel, AMD and Nvidia reports"  }

  
  // Add more questions as needed
];

const DiscoverPage = ({ newMessage, setNewMessage, setActiveThread, threads, setThreads, navigate,setStreamActive }) => {
  const user_value_cookie = Cookies.get("val1");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [user_value_cookie]);

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  const handleItemClick = (question) => {
    const newChatId = generateRandomId();
    const newChatName = `Chat-${threads.filter((thread) => thread.id !== newChatId).length + 1}`;
    const newChat = {
      id: newChatId,
      name: 'test',
      messages: [],
    };
    // console.log(newChat)
    setActiveThread(newChat);
    setThreads([...threads, newChat]);
    setNewMessage(question?.name);
  
    navigate("/");
  };

  return (
    <div>
      <div
        style={{
          marginLeft: "10%",
          marginTop: isMobile ? "20%" : "5%",
          marginBottom: "10%",
        }}
      >
        <List
          itemLayout="vertical"
          dataSource={predefinedQuestions}
          renderItem={(question) => (
            <List.Item
              onClick={() => handleItemClick(question)}
              actions={[
                <IconText
                  icon={StockOutlined}
                  text="Ticker: AAPL, AMZN"
                  key="list-vertical-like-o"
                />,
                <IconText
                  icon={FieldTimeOutlined}
                  text="2 days ago"
                  key="list-vertical-message"
                />,
              ]}
              style={{
                cursor: "pointer",
                transition: "background-color 0.3s",
                paddingLeft: isMobile ? "0%" : "5%",
                marginRight: isMobile ? "0%" : "20%",
              }}
              className="list-item-hover"
            >
              <List.Item.Meta
                title={question.name}
                description={question.message}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};


export default DiscoverPage;

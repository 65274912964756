import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCVA6Vozgr-Gz_o65e8AAriSRC6-HR2IQA",
  authDomain: "stockbuzz-d1919.firebaseapp.com",
  projectId: "stockbuzz-d1919",
  storageBucket: "stockbuzz-d1919.appspot.com",
  messagingSenderId: "244690539733",
  appId: "1:244690539733:web:af67d557cfcc03e9ba3e5c",
  measurementId: "G-VTMWN3BKHD",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
